import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { selectIsInstancesLoaded } from '@dimatech/features-survey/lib/api/filter/filterSlice';
import { SelectEntityWithSurvey } from '@dimatech/features-survey/lib/components/SelectEntityWithSurvey';
import { SelectSurveyPeriod } from '@dimatech/features-survey/lib/components/SelectSurveyPeriod';
import { Select } from '@dimatech/shared/lib/components/form';
import { pcvActions, selectFilter } from 'api/pcvSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EntityType, FilterableEntityType, SurveyInstance } from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const SelectExtendedFilters = ({
  isEntityTypeFilterShown = false,
  isPeriodFilterShown = true,
  isOnlyPeriodsWithDataShown = true,
}: {
  isEntityTypeFilterShown?: boolean;
  isPeriodFilterShown?: boolean;
  isOnlyPeriodsWithDataShown?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);

  const filter = useAppSelector(selectFilter);
  const isInstancesLoaded = useSelector(selectIsInstancesLoaded);

  useEffect(() => {
    if (
      isEntityTypeFilterShown &&
      accessToken.user?.currentCustomer.customerType
    ) {
      let entityType = accessToken.user.currentCustomer
        .customerType as FilterableEntityType;

      if (
        !(
          [
            EntityType.Municipality,
            EntityType.Government,
            EntityType.Region,
          ] as FilterableEntityType[]
        ).includes(entityType)
      ) {
        entityType = EntityType.Other;
      }

      handleChangeEntityType(entityType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEntityTypeFilterShown, accessToken.customerId]);

  const handleChangeInstance = (
    instance: SurveyInstance,
    previousInstance?: SurveyInstance
  ) => {
    dispatch(pcvActions.setFilterEntityId(''));
    dispatch(pcvActions.setFilterInstance({ instance, previousInstance }));
  };

  const handleChangeEntity = (entityId: string) => {
    dispatch(pcvActions.setFilterEntityId(entityId));
  };

  const handleChangeEntityType = (entityType: EntityType) => {
    dispatch(
      pcvActions.setFilter({
        ...filter,
        entityType,
      })
    );
  };

  return (
    <Style>
      <div>
        <div>
          {isPeriodFilterShown && (
            <SelectSurveyPeriod
              instance={filter.instance}
              setInstance={handleChangeInstance}
              isOnlyPeriodsWithDataShown={isOnlyPeriodsWithDataShown}
              isOnlyPeriodsFrom2022Shown={false}
            />
          )}

          {!isEntityTypeFilterShown && isInstancesLoaded && (
            <SelectEntityWithSurvey
              style={{ minWidth: 150, maxWidth: 'unset' }}
              entityId={filter.entityId}
              setEntityId={handleChangeEntity}
              surveyInstanceId={filter.instance?.id}
            />
          )}

          {isEntityTypeFilterShown && (
            <Select
              style={{ minWidth: 150 }}
              value={filter.entityType}
              onChange={(e) =>
                handleChangeEntityType(e.currentTarget.value as EntityType)
              }
            >
              {Object.values(FilterableEntityType).map((enumValue) => (
                <option key={enumValue} value={enumValue}>
                  {t(`CommonTerms.${enumValue}`)}
                </option>
              ))}
            </Select>
          )}
        </div>
      </div>
    </Style>
  );
};

SelectExtendedFilters.displayName = 'SelectExtendedFilters';

const Style = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    > div:first-of-type {
      margin: 0;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 5px 0;
    }
  }
`;
