import { AdministrateOrganisation as AdministrateOrganisationFeature } from '@dimatech/features-survey/lib/features/administrate-organisation';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateOrganisation = (): JSX.Element | null => {
  const { t } = useTranslation();

  const isViewersEnabledFlagOn =
    useFlags()[flags.permanent.app.pcv.isViewersEnabled];

  return (
    <View title={t('Administrate.Entity.Title')}>
      <AdministrateOrganisationFeature
        isViewersEnabledFlagOn={isViewersEnabledFlagOn}
      />
    </View>
  );
};

AdministrateOrganisation.displayName = 'AdministrateOrganisation';
