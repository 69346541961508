import { Header } from '@dimatech/features-core/lib/components/Header';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import {
  Banner,
  FeatureCentered,
  View as SharedView,
  Workspace,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useTitle } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

export const Maintenance = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element => {
    const { t } = useTranslation();

    const maintenanceMessage = useFlags()[
      flags.permanent.app.pcv.maintenanceMessage
    ] as string;

    useTitle(maintenanceMessage?.length > 0 ? '' : t('Maintenance.Title'));

    return (
      <SharedView>
        <Banner>
          <Header themes={{ dark, light }} />
        </Banner>

        <Workspace
          style={{
            justifyContent: 'center',
            backgroundColor: `${theme.colors.background}`,
          }}
        >
          <FeatureCentered style={{ width: 300 }}>
            {maintenanceMessage?.length > 0 ? (
              <div style={{ marginTop: 20 }}>
                {maintenanceMessage || t('Maintenance.Text')}
              </div>
            ) : (
              <>
                <Heading2 style={{ marginTop: 20 }}>
                  {t('Maintenance.Title')}
                </Heading2>

                <div style={{ marginTop: 20 }}>
                  {maintenanceMessage || t('Maintenance.Text')}
                </div>
              </>
            )}
          </FeatureCentered>
        </Workspace>
      </SharedView>
    );
  }
);

Maintenance.displayName = 'Maintenance';
