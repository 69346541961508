import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

// Initialize an empty Pcv api service that we'll inject endpoints into

export enum CacheTags {
  EntityReport = 'EntityReport',
  ProgressByEntity = 'ProgressByEntity',
  Overview = 'Overview',
  Progress = 'Progress',
  OverviewByOption = 'OverviewByOption',
  OverviewByCustomer = 'OverviewByCustomer',
  OverviewByEntity = 'OverviewByEntity',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();

// Api
export const pcvApi = createApi({
  reducerPath: 'pcvApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(CacheTags).join(',')],
  baseQuery: axiosBaseQueryWithReAuthentication({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
