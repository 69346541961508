import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from 'config';
import { saveAs } from 'file-saver';
import { RootState } from 'store';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getHistoricalResearcher = createAsyncThunk(
  'report/historical/researcher',
  async ({ reportId, fileName }: { reportId: string; fileName: string }) => {
    return axios({
      url: `${config.apiUrl.product.current}reports/historical/${reportId}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const file = new File([response.data], fileName);

      saveAs(file, fileName);
    });
  }
);

export const {
  reducer: reportHistoricalResearcherReducer,
  actions: reportHistoricalResearcherActions,
} = createSlice({
  name: 'report/historical/researcher',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(getHistoricalResearcher.fulfilled, (state) => {
        state.isPosting = false;
      })
      .addCase(getHistoricalResearcher.pending, (state) => {
        state.isPosting = true;
        state.error = null;
      })
      .addCase(getHistoricalResearcher.rejected, (state, action) => {
        state.isPosting = false;
        state.error = action.error.name;
      });
  },
});

export const selectReportHistoricalResearcherError = (
  state: RootState
): string | null | undefined => state.report.historicalResearcher.error;

export const selectReportHistoricalResearcherIsPosting = (
  state: RootState
): boolean => state.report.historicalResearcher.isPosting;
