import { OrderPios as OrderPiosComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { config } from '@dimatech/shared/lib/config';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const OrderPios = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <ViewCentered
      title={t('Order.Pios.Title')}
      style={{ width: '80%', maxWidth: 650 }}
    >
      <OrderPiosComponent
        fromProductLink={
          <a href={config.products.pcv.url}>
            {t('Order.VerificationLinkTextPcv')}
          </a>
        }
      />
    </ViewCentered>
  );
};

OrderPios.displayName = 'OrderPios';
