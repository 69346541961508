import { RespondentsFailedMessages as RespondentsFailedMessagesFeature } from '@dimatech/features-survey/lib/features/administrate-respondents/RespondentsFailedMessages';
import { CacheTags, pcvApi } from 'api/pcvApi';
import { useAppDispatch } from 'hooks';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const AdministrateRespondentsFailedMessages = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleRespondentMovedOrDeleted = () => {
    dispatch(
      pcvApi.util.invalidateTags([
        CacheTags.Overview,
        CacheTags.Progress,
        CacheTags.ProgressByEntity,
        CacheTags.OverviewByOption,
        CacheTags.OverviewByCustomer,
        CacheTags.OverviewByEntity,
      ])
    );
  };

  return (
    <View title={t('Administrate.Respondent.FailedMessages.Title')}>
      <RespondentsFailedMessagesFeature
        handleRespondentMovedOrDeleted={handleRespondentMovedOrDeleted}
      />
    </View>
  );
};

AdministrateRespondentsFailedMessages.displayName =
  'AdministrateRespondentsFailedMessages';
