import { ApplicationContext } from '@dimatech/shared/lib/application';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody } from '@dimatech/shared/lib/components/workspace';
import { ViewHeader } from 'components/ViewHeader';
import { config } from 'config';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { View } from 'views';

export const Help = (): JSX.Element => {
  const { t } = useTranslation();
  const application = useContext(ApplicationContext);

  return (
    <View title={t('Help.Title')}>
      <ViewHeader>
        <Heading1>{t('Help.Title')}</Heading1>

        {config.version && (
          <Heading1>
            {' '}
            - {application.title} v{config.version}
          </Heading1>
        )}
      </ViewHeader>

      <Card title={t('Help.Support')}>
        <CardBody>
          <Style>
            <Trans
              i18nKey="Help.Text"
              values={{
                supportUrl: config.support.url,
                supportEmail: config.support.email,
              }}
            >
              <a href={config.support.url} target="_blank" rel="noreferrer">
                {config.support.url}
              </a>
              <a href={`mailto: ${config.support.email}`}>
                {config.support.email}
              </a>
            </Trans>
          </Style>
        </CardBody>
      </Card>

      <Card title={t('Help.Messaging')}>
        <CardBody>
          <Style>
            <BsExclamationCircleFill
              className="size-xl"
              style={{ marginRight: 8 }}
            />
            {t('Administrate.Schedule.Help.Whitelisting', {
              email: config.support.productEmail,
            })}

            <p>{t('Administrate.Schedule.Help.TestMessage')}</p>
          </Style>
        </CardBody>
      </Card>

      <Card title={t('Help.Anonymity.Title')}>
        <CardBody>
          <Style>{t('Help.Anonymity.Text')}</Style>
        </CardBody>
      </Card>
    </View>
  );
};

Help.displayName = 'Help';

const Style = styled.div`
  max-width: 900px;

  > div {
    margin-bottom: 20px;
  }
`;
