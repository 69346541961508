import { AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card } from '@dimatech/shared/lib/components/workspace';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { ViewHeader } from 'components/ViewHeader';
import { useTranslation } from 'react-i18next';

export const Overview = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Overview.Title')}</Heading1>
        <SelectExtendedFilters />
      </ViewHeader>

      <Card width="100%">
        <AlertInfo>NOT IMPLEMENTED</AlertInfo>
      </Card>
    </>
  );
};

Overview.displayName = 'Overview';
