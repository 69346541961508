import {
  base,
  Color,
  ProductPrimaryColor,
  Theme,
  ThemeType,
} from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Pcv Light',
  type: ThemeType.Light,

  colors: {
    ...base.colors,

    primary: ProductPrimaryColor.Pcv,
    primaryVariant: Color.DimiosBlue,
    onPrimary: Color.White,

    secondary: Color.DarkTurquoise,
    secondaryVariant: Color.SeaGreen,
    onSecondary: Color.White,

    chart: {
      ...base.colors.chart,

      primary: ProductPrimaryColor.Pcv,
      primaryHighlight: Color.DimiosBlueDark,

      secondary: Color.SandyBrown,
      secondaryHighlight: Color.KojiOrangeLight,
    },
  },
};
